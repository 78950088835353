export const operatorsFramework = 'https://github.com/operator-framework';
export const operatorsRepo = `${operatorsFramework}/community-operators`;
export const operatorsDocumentation = `https://operator-framework.github.io/community-operators`
export const contributions = `${operatorsRepo}/tree/master/upstream-community-operators`;
export const operatorsRepoBeforePR = `${operatorsDocumentation}/contributing-prerequisites`;
export const operatorsRepoRequirements = `${operatorsDocumentation}/packaging-operator`;
export const operatorSdk = `${operatorsFramework}/operator-sdk`;
export const sdkQuickstartBundle = `https://sdk.operatorframework.io/docs/olm-integration/quickstart-bundle/`;
export const operatorCourier = `${operatorsFramework}/operator-courier`;
export const operatorScorecard = `${operatorSdk}/blob/master/doc/test-framework/scorecard.md`;
export const operatorBundle = `${operatorsRepo}#adding-your-operator`;
export const olm = `${operatorsFramework}/operator-lifecycle-manager`;
export const gettingStarted = `https://operatorframework.io`;
export const operatorRegistry = `${operatorsFramework}/operator-registry`;
export const bundleAnnotations = `${operatorsFramework}/operator-registry/blob/master/docs/design/operator-bundle.md#bundle-annotations`;
export const bundleDockerfile = `${operatorsFramework}/operator-registry/blob/master/docs/design/operator-bundle.md#bundle-dockerfile`;
export const manualTestingOnKubernetes = `${operatorsDocumentation}/operator-test-suite`;
export const operatorMetadataValidation = `${operatorsDocumentation}/testing-operators`;
export const prometheusOperator = `${contributions}/prometheus`;
export const prometheusOperatorVersion = `${contributions}/prometheus/0.22.2`;
export const olmArchitecture = `${olm}/blob/master/doc/design/architecture.md`;
export const buildYourCSV = `${operatorsDocumentation}/packaging-operator`;
export const createPackageManifest = `${operatorsDocumentation}/packaging-operator`;
export const semanticVersioning = `https://semver.org/`;
export const createBundle = `${operatorsRepo}/blob/master/docs/contributing.md#create-a-release-using-the-bundle-format`;
export const packagemanifestToBundle = `${operatorsDocumentation}/packaging-operator`;
export const discoveryCatalogs = `${olm}#discovery-catalogs-and-automated-upgrades`;
export const introBlog = `https://web.archive.org/web/20170129131616/https://coreos.com/blog/introducing-operators.html`;
export const sampleCode = `${operatorsFramework}/operator-sdk/blob/master/testdata/go/v3/memcached-operator/controllers/memcached_controller.go#L51-L137`;
export const capabilityLevelModelDiagram = `${operatorSdk}/blob/master/doc/images/operator-capability-level.png`;
export const manageOperatorWithOlm = `https://olm.operatorframework.io`;
export const operatorGroupDesign = `${olm}/blob/master/doc/design/architecture.md#operator-group-design`;
export const privacyPolicy = `https://www.redhat.com/en/about/privacy-policy`;
export const kubernetesSlack = `https://kubernetes.slack.com/messages/kubernetes-operators`;
export const hubTwitter = `https://twitter.com/operatorhubio`;
export const hubYoutube = 'https://www.youtube.com/watch?v=yzPvPJLxCl8&list=PLaR6Rq6Z4Iqcuu758T4YX6KRa1158v3Rb';
export const fileAnIssue = `${operatorsRepo}/issues`;
